import { request } from './request'

export function speed() {
  return request({
    method: 'GET',
    url: 'speed',
  })
}

export function speedCz() {
  return request({
    method: 'GET',
    url: 'speed_cz',
  })
}

export function jumplink() {
  return request({
    method: 'POST',
    url: 'jumplink',
  })
}
