import axios from 'axios'
import qs from 'qs'
export function request(config) {
  // 1.创建axios的实例
  const instance = axios.create({
    //  baseURL: 'https://api.bapo81776.com/api/',
    baseURL: '/api',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  instance.defaults.headers.post['Content-Type'] =
    'Content-Type:application/x-www-form-urlencoded; charset=UTF-8'
  instance.interceptors.request.use(
    (config) => {
      if (config.method === 'post' && !(config.data instanceof FormData)) {
        config.data = qs.stringify(config.data)
      }
      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  // 3.发送真正的网络请求
  return instance(config)
}
